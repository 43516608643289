import React from "react";
import { graphql } from "gatsby";
import { Router } from '@reach/router';

import Layout from "../components/Layout";
import "../assets/css/main.scss";

// import Auth from "../components/Auth/Auth";

// import Homepage from "../components/Homepage/Homepage";
// import Menubar from "../components/Menubar/Menubar";

import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Hero";
import Reviews from "../components/Reviews";
import BelowFoldHero from "../components/BelowFoldHero/BelowFoldHero";
import NewsarticleSection from "../components/NewsarticleSection";

//import Callback from "./auth/microsoft/callback";

import SEO from "../components/seo";

const IndexPage = ({ data }) => {

  const { homeTitle, homeDescription } = data?.seo?.nodes[0];

  const {
    allStrapiNewsarticle: { nodes: newsItems },
    allStrapiReview: { nodes: reviews },
    allStrapiHomePage: { nodes: heroContents }
  } = data;

  const LazyComponent = ({ Component, ...props }) => (
    <React.Suspense fallback={'<p>Loading...</p>'}>
      <Component {...props} />
    </React.Suspense>
  );

  return (
    <Layout key="layout">
      <SEO title={homeTitle} description={homeDescription}></SEO>
      <Hero heroContents={heroContents}></Hero>
      <NewsarticleSection heroContents={heroContents} newsItems={newsItems}></NewsarticleSection>
      <BelowFoldHero heroContents={heroContents}></BelowFoldHero>
      <Reviews heroContents={heroContents} reviews={reviews}></Reviews>
      <Router>
        {/* <LazyComponent Component={Callback} path="/auth/microsoft/callback" /> */}
        {/* <LazyComponent Component={Homepage} path="/" /> */}
        {/* <LazyComponent Component={Newsfeeds} path="newsfeeds" />  */}
        {/* <LazyComponent Component={Guidelines} path="guidelines" /> */}
      </Router>
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiNewsarticle {
      nodes {
        title
        slug
        id
        publishedDate(formatString: "MMMM D YYYY")
      }
    },
    allStrapiHomePage {
      nodes {
        HeroContentP {
          contents
        }
        heroctatext
        heroctalink
        GuidelinesTextP {
          contents
        }
        SecondHeroContentP {
          contents
        }
        secondheroctatext
        secondheroctalink
        reviewsTitle
        footerText
      }
    },
    allStrapiReview {
      nodes {
        id
        name
        text
        link
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    seo: allStrapiSeo {
      nodes {
        homeDescription
        homeTitle
      }
    }
  }
`;


export default IndexPage;