import { Link } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewsarticleSection = ({heroContents, newsItems}) => {

  return (
    <section className="section">
        <div className="container">
            <Row>
                <Col xs={12} md={7} sm={7} lg={7}>
                    <div className="col-md-12 text-center">
                        <FontAwesomeIcon icon="list-alt" color="blue" />
                        <img src="https://www.iconbolt.com/iconsets/font-awesome-regular/list-alt.svg" height="50px" />
                        <Link to="/guidelines">
                            <h3>Florabank Guidelines</h3>
                            <p style={{"color": "black"}}>
                                {/* The Australian benchmark for<br></br> best practice in native seed<br></br> collection, processing and use */}
                                <div dangerouslySetInnerHTML={{__html: heroContents[0].GuidelinesTextP.contents}}></div>
                            </p>
                        </Link>
                    </div>
                </Col>
                <Col xs={12} md={5} sm={5} lg={5}>
                    <div className="col-md-12">
                        <h3 className="text-center">Latest Florabank News</h3>
                        <div className="underline"></div>
                        <div className="newsline">
                            { newsItems.map( newsitemsingle => {
                                return (
                                    <div>
                                        <Link key={newsitemsingle.slug} to={`/news/${newsitemsingle.slug}`} ><p>{newsitemsingle.title}<br></br><span style={{"color": "black"}}>- {newsitemsingle.publishedDate}</span></p></Link>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </section>
  )
}

export default NewsarticleSection;
