import React, { useState } from "react";
import Title from "./Title";
import Image from "gatsby-image"
import { Carousel, CarouselItem } from "react-bootstrap";
import { Link } from "gatsby";

const Reviews = ({heroContents, reviews}) => {

  let carouselItems = [];

  for (let reviewElement = 0; reviewElement < reviews.length; reviewElement++) {
    let reviewCopy = [...reviews]; 
    
    // pop off the first element
    reviewCopy.splice(0, 1);  

    // construct array with remaining elements in reviewCopy + first element
    reviews = [...reviewCopy, reviews[0]] 
    
    carouselItems.push(
      <CarouselItem >
        <div className="row">
          { reviews.map(review => {
            const {id, name, image, text, link} = review;
            return (
                <div key={id} className="col-md-3"> 
                  <Link to={link}>
                    {image ? 
                      <Image className="rounded" alt={name} fluid={image.childImageSharp.fluid} />
                      : <></>
                    }
                    <h4>{name}</h4>
                    <div className="underline"></div>
                    <p>{text}</p>
                  </Link>
                </div>
              )
          })}
        </div>
      </CarouselItem>
    )
  }

  return (
    <section className="section bg-grey">
      <Title title={heroContents[0].reviewsTitle} />
      <div className="row">
        <Carousel>
          {carouselItems}
        </Carousel>
      </div>
    </section>
  )
}

export default Reviews;
