import React from "react"
import { useMsal } from "@azure/msal-react";
import { Link } from "gatsby";

import { getIsUserAuthenticated } from "../../services/auth";

import "./BelowFoldHero.scss";

const BelowFoldHero = ({ heroContents }) => {
  const { instance } = useMsal();
  const isAuthenticated = getIsUserAuthenticated();
  
  return (
  <header>
    <div className="jumbotron-below-fold jumbotron-fluid belowFoldHero">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 text-center secondHero">
              <div dangerouslySetInnerHTML={{__html: heroContents[0].SecondHeroContentP.contents}}></div>
              {
                isAuthenticated ? <></> :
                <>
                  <br></br>
                  <Link type="button" className="btn btn-primary mb-4" to={process.env.GATSBY_SIGNUP_URL}>{heroContents[0].heroctatext}</Link>
                </>
              }
            </div>
          </div>
        </div>
    </div>
  </header>
  )
}

export default BelowFoldHero;
